<template>
	<div class="box pc" v-if="viewRatio > 3 / 5">
		<div class="video-image-width">
			<div class="play-iconpc-container">
				<img class="play-iconpc-wx" src="/commencement/images/streamqrcode.png" alt="none" />
			</div>
			<div class="btn-group-outvedio">
				<a href="javascript:;" @click="line('in')" class="purple">
					线路选项一
					<span>Bilibili</span>
				</a>
				<a href="javascript:;" @click="line('out')" class="purple">
					线路选项二
					<span>Overseas Lines</span>
				</a>
				<a href="javascript:;" class="yellow" @click="line('in2')">
					线路选项三
					<span>Wechat Stream</span>
				</a>
			</div>
		</div>
	</div>
	<div class="box mobile" v-else>
		<div class="video-image-height">
			<img class="top-image" src="/commencement/images/bk-1.png" alt="none" />
			<img class="NYU" src="/commencement/images/NYUlogo.svg" />
			<img class="classof" src="/commencement/images/class.svg" />
			<img class="number" src="/commencement/images/2024.svg" />
			<img class="postgraduate" src="/commencement/images/graduate.svg" />
			<img class="play-iconpc" src="/commencement/images/streamqrcode.png" alt="none" />

			<div class="btn-group-outvedio">
				<a href="javascript:;" @click="line('in')" class="purple">
					线路选项一
					<span>Bilibili</span>
				</a>
				<a href="javascript:;" @click="line('out')" class="purple">
					线路选项二
					<span>Overseas Lines</span>
				</a>
				<a href="javascript:;" class="yellow" @click="line('in2')">
					线路选项三
					<span>Wechat Stream</span>
				</a>
			</div>
			<img class="end-image" src="../../../public/commencement/images/bk-1.png" alt="none" />
		</div>
	</div>
</template>
<script>
export default {
	name: "nyu-youtube",
	data() {
		return {
			isplay: false,
			isShow: false,
			isWeChat: false,
			ismobile: false,
			viewRatio: 16 / 9,
			id: "",
			hideBtn: false,
			inOrOut: "in",
			isLoading: true,
			youtube: process.env.VUE_APP_ID,
			url: "https://www.bilibili.com/blackboard/live/live-activity-player.html",
		};
	},
	created() {
		this.fetchConfig();
	},
	mounted() {
		this.onResize();
		this.init();
		window.addEventListener("resize", this.onResize, false);
	},
	destroyed() {
		window.removeEventListener("resize", this.onResize);
	},
	methods: {
		onResize() {
			console.log(this.isplay);
			const last_viewRatio = this.viewRatio;
			this.viewRatio = window.innerWidth / window.innerHeight;
			if (last_viewRatio <= 3 / 5 && this.viewRatio > 3 / 5) {
				this.isplay = true;
			}
		},
		fetchConfig() {
			const noCache = new Date().getTime();
			fetch(`../zhibo/config/config.json?${noCache}`)
				.then((response) => response.json())
				.then((data) => {
					this.id = data["BILIBILI"];
					this.isLoading = false;
				})
				.catch((error) => console.error("Error fetching config:", error));
		},
		init() {
			const isMobile = navigator.userAgent.toLowerCase().includes("mobile");
			const isPortrait = window.matchMedia("(orientation: portrait)").matches;
			if (isMobile && isPortrait) {
				this.isShow = true;
				this.ismobile = true;
			} else {
				this.isShow = false;
				if (isMobile) {
					this.ismobile = true;
				}
			}
		},
		playHandler() {
			this.isplay = true;
			this.isShow = true;
		},

		line(type) {
			if (type == "out") {
				this.$router.push("/overseas");
			}
			if (type == "in") {
				this.$router.push("/mainland");
			}
			if (type == "in2") {
				this.$router.push("/mainland2");
			}
		},
	},
};
</script>
<style>
.play-iconpc-container {
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.15);
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 35vw;
	height: 35vw;
}
.play-iconpc-wx {
	width: 50%;
	height: auto;
	border-radius: 10px;
}
.play-iconpc {
	opacity: 1;
	width: 35%;
	height: auto;
}
</style>
