<template>
	<div class="box pc" v-if="viewRatio > 3 / 5">
		<div class="video-image-width">
			<img
				class="play-iconpc"
				src="/commencement/images/bilibili.png"
				alt="none"
				@click="playHandler"
				v-if="!isplay"
			/>
			<div class="btn-group-outvedio">
				<a href="javascript:;" @click="line('in')" class="yellow">
					线路选项一
					<span>Bilibili</span>
				</a>
				<a href="javascript:;" @click="line('out')" class="purple">
					线路选项二
					<span>Overseas Lines</span>
				</a>
				<a href="javascript:;" class="purple" @click="line('in2')">
					线路选项三
					<span>Wechat Stream</span>
				</a>
			</div>
			<div class="iframe-box" v-if="isplay">
				<iframe
					class="iframe"
					v-if="!isLoading && !ismobile"
					:src="`https://live.bilibili.com/blackboard/activity-player-v3.html?cid=${id}&entrance=0&sendpanel=0&danmaku=0&recommend=0&enableAutoPlayTips=0`"
					frameborder="no"
					framespacing="0"
					scrolling="no"
					allow="autoplay; encrypted-media"
					allowfullscreen="true"
				></iframe>
			</div>
		</div>
	</div>
	<div class="box mobile" v-else>
		<div class="video-image-height">
			<img class="top-image" src="/commencement/images/bk-1.png" alt="none" />
			<img class="NYU" src="/commencement/images/NYUlogo.svg" />
			<img class="classof" src="/commencement/images/class.svg" />
			<img class="number" src="/commencement/images/2024.svg" />
			<img class="postgraduate" src="/commencement/images/graduate.svg" />
			<div class="iframe-box iframe-jump">
				<img
					class="play-iconpc"
					src="/commencement/images/bilibili.png"
					alt="none"
					@click="playHandler"
					v-if="!isplay"
				/>
				<!-- <iframe
					class="iframe"
					v-if="!isLoading"
					:src="`https://live.bilibili.com/blackboard/activity-player-v3.html?cid=${id}&entrance=0&sendpanel=0&danmaku=0&recommend=0&enableAutoPlayTips=0`"
					frameborder="no"
					framespacing="0"
					scrolling="no"
					allow="autoplay; encrypted-media"
					allowfullscreen="true"
				></iframe> -->
				<!-- <iframe
					class="iframe"
					v-if="!isLoading"
					:src="`${url}?roomId=${id}&quality=1&autoplay=${ismobile ? 0 : 1}`"
					frameborder="0"
					allow="*"
					allowfullscreen
				></iframe> -->
				<div class="btn-group-invedio" v-if="isplay">
					<a href="javascript:;" @click="line('in')" class="yellow">
						线路选项一
						<span>Bilibili</span>
					</a>
					<a href="javascript:;" @click="line('out')" class="purple">
						线路选项二
						<span>Overseas Lines</span>
					</a>
					<a href="javascript:;" class="purple" @click="line('in2')">
						线路选项三
						<span>Wechat Stream</span>
					</a>
				</div>
			</div>
			<div class="btn-group-outvedio">
				<a href="javascript:;" @click="line('in')" class="yellow">
					线路选项一
					<span>Bilibili</span>
				</a>
				<a href="javascript:;" @click="line('out')" class="purple">
					线路选项二
					<span>Overseas Lines</span>
				</a>
				<a href="javascript:;" class="purple" @click="line('in2')">
					线路选项三
					<span>Wechat Stream</span>
				</a>
			</div>
			<img class="end-image" src="../../../public/commencement/images/bk-1.png" alt="none" />
		</div>
	</div>
</template>
<script>
export default {
	name: "nyu-youtube",
	data() {
		return {
			isplay: false,
			isShow: false,
			isWeChat: false,
			ismobile: false,
			viewRatio: 16 / 9,
			id: "",
			hideBtn: false,
			inOrOut: "in",
			isLoading: true,
			youtube: process.env.VUE_APP_ID,
			url: "https://live.bilibili.com/",
			// url: "https://www.bilibili.com/blackboard/live/live-mobile-playerV3.html",
		};
	},
	created() {
		this.fetchConfig();
	},
	mounted() {
		this.onResize();
		this.init();
		window.addEventListener("resize", this.onResize, false);
	},
	destroyed() {
		window.removeEventListener("resize", this.onResize);
	},
	methods: {
		onResize() {
			this.viewRatio = window.innerWidth / window.innerHeight;
		},
		fetchConfig() {
			const noCache = new Date().getTime();
			fetch(`../zhibo/config/config.json?${noCache}`)
				.then((response) => response.json())
				.then((data) => {
					this.id = data["BILIBILI"];
					this.isLoading = false;
				})
				.catch((error) => console.error("Error fetching config:", error));
		},
		init() {
			this.ismobile = navigator.userAgent.toLowerCase().includes("mobile");
			const isPortrait = window.matchMedia("(orientation: portrait)").matches;
			if (this.ismobile && isPortrait) {
				this.isShow = true;
			} else {
				this.isShow = false;
			}
		},
		playHandler() {
			if (this.ismobile) {
				window.location.href = "https://live.bilibili.com/" + this.id;
				return false;
			}
			this.isplay = true;
			this.isShow = true;
		},
		line(type) {
			if (type == "out") {
				this.$router.push("/overseas");
			}
			if (type == "in2") {
				this.$router.push("/mainland2");
			}
		},
	},
};
</script>
<style>
.box {
	height: 100%;
	width: 100%;
}

.top-image {
	transform: rotate(180deg);
}

.video-image-width {
	background-image: url("../../assets/2024/2024mastervk.jpg");
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}
.btn-group-outvedio,
.btn-group-invedio {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: auto;
}

.video-image-width .btn-group-outvedio {
	z-index: 10;
	bottom: 5%;
	position: absolute;
	width: auto;
}

.btn-group-outvedio > a,
.btn-group-invedio > a {
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 20px;
	text-decoration: none;
	width: 200px;
	padding: 16px;
	box-sizing: border-box;
	line-height: 1.5;
	font-weight: 700;
	font-size: 22px;
}
.btn-group-outvedio > a.yellow,
.btn-group-invedio > a.yellow {
	color: rgb(255, 255, 255);
	background-color: rgb(255, 186, 1);
	border: 2px solid rgb(255, 255, 255);
}
.btn-group-outvedio > a.purple,
.btn-group-invedio > a.purple {
	color: rgb(255, 186, 1);
	background-color: rgb(96, 15, 168);
	border: 2px solid rgb(255, 186, 1);
}

.btn-group-outvedio > a > span,
.btn-group-invedio > a > span {
	font-size: 16px;
	font-family: "Noto Sans SC", Times, serif;
	font-weight: 400;
}
.play-iconpc {
	opacity: 0.7;
	width: 35%;
	height: auto;
}

.btn-group-outvedio > a:focus,
.btn-group-invedio > a:focus {
	outline: none;
}

.video-image-height {
	background-color: #57068c;
	background-image: url(../../../public/commencement/images/2024ConvocationKV.png);
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	height: auto;
	width: 100%;
	display: flex;
	align-items: center;
	text-align: center;
	flex-direction: column;
}

.NYU {
	width: 20%;
	height: auto;
	margin-top: 0%;
}

.shanghai {
	width: 54%;
	height: auto;
}
.postgraduate {
	width: 68%;
	height: auto;
	margin-top: 6%;
	margin-bottom: 6%;
}
.classof {
	margin-top: 6%;
	width: 30%;
	height: auto;
}
.number {
	width: 40%;
	height: auto;
	margin-top: 5%;
}
.iframe {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	transform: translate(-50%, -50%);
}
.play-icon {
	width: 45%;
	height: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.active {
	color: white;
}
.end-image {
	width: 100%;
	height: auto;
	margin-top: auto;
	bottom: 0;
}
.pc .iframe-box {
	/* z-index: 999; */
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	border: 0;
}

/* Phone */
@media (max-width: 667px) {
	.box {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	.video-image-height {
		min-height: 100%;
	}
}

/* pc */
@media (min-width: 660px) {
	.box {
		min-width: 660px;
		margin-left: auto;
		margin-right: auto;
		overflow: hidden;
	}
}

.pc .iframe-box {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	border: 0;
}

.mobile .iframe-box {
	position: relative;
	width: 100%;
	padding-top: 56.2%;
}

.btn-group-outvedio > a > span,
.btn-group-invedio > a > span {
	font-size: 16px;
	font-family: "Noto Sans SC", Times, serif;
	font-weight: 400;
}
.mobile .iframe-box.iframe-jump {
	background-image: url("../../assets/2024/2024mastervk.jpg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #000;
	position: relative;
	border: 2px solid #fff;
	max-width: 94%;
}
.iframe-jump .play-iconpc {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}


@media (min-height: 200px) and (max-height: 700px) {
	.box {
		margin-left: auto;
		margin-right: auto;
	}
	.btn-group-outvedio > a {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 10px;
		text-decoration: none;
		width: 100px;
		padding: 8px;
		box-sizing: border-box;
		line-height: 1;
		font-weight: 700;
		font-size: 14px;
	}
	.btn-group-invedio > a {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 10px;
		text-decoration: none;
		width: 23vw;
		padding: 8px;
		box-sizing: border-box;
		line-height: 1;
		font-weight: 700;
		font-size: 12px;
	}
}

@media (min-width: 200px) and (max-width: 700px) {
	.btn-group-outvedio > a {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 10px;
		text-decoration: none;
		width: 100px;
		padding: 8px;
		box-sizing: border-box;
		line-height: 1;
		font-weight: 700;
		font-size: 14px;
	}
	.btn-group-invedio > a {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 10px;
		text-decoration: none;
		width: 23vw;
		padding: 8px;
		box-sizing: border-box;
		line-height: 1;
		font-weight: 700;
		font-size: 12px;
	}
}

@media screen and (orientation: landscape) {
	.mobile .box {
		padding-top: 56%;
	}
	.video-image-height .iframe-box,
	.iframe-box {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
		padding-top: 0%;
	}
	/* Phone */
	@media (max-width: 668px) {
		.box {
			width: 100%;
			margin-left: auto;
			margin-right: auto;
		}
		.video-image-height {
			min-height: 100%;
		}
	}
}
</style>
