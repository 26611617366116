import Vue from 'vue'
import VueRouter from 'vue-router'
import jquery from 'jquery';

import App from '@/App.vue'
import i18n from '@/locale/index'

import Layout2023 from '@/layouts/layout-2023'
import Bilibili from '@/pages/2023/bilibili.vue';
import Youtube from '@/pages/2023/youtube.vue';
import Wxstream from '@/pages/2023/wxstream.vue';
// import JWPlayerVue from './JWPlayer.vue'
// import YoutubeVue from './Youtube.vue'

Vue.config.productionTip = false
Vue.prototype.$jq = jquery;
window.jquery = jquery;

const router = new VueRouter({
	routes: [
		{
			path: '/', component: Layout2023, redirect: process.env.VUE_APP_REDIRECT || '/mainland', children: [
				{ path: '/mainland', component: Bilibili },
				{ path: '/overseas', component: Youtube },
				{ path: '/mainland2', component: Wxstream }
			]
		},
		// { path: '/domestic', component: JWPlayerVue },
		// { path: '/overseas', component: YoutubeVue }
	]
})

Vue.use(VueRouter)

new Vue({
	i18n,
	router,
	render: h => h(App),
}).$mount('#app')
