<template>
	<div class="box pc" v-if="viewRatio > 3 / 5">
		<div class="video-image-width">
			<img class="play-iconpc" src="../../../public/commencement/images/youtube.png" alt="none"
				@click="playHandler" v-if="!isplay" />
			<div class="btn-group-outvedio">
				<a href="javascript:;" @click="line('in')" class="purple">
					线路选项一
					<span>Bilibili</span>
				</a>
				<a href="javascript:;" @click="line('out')" class="yellow">
					线路选项二
					<span>Overseas Lines</span>
				</a>
				<a href="javascript:;" class="purple" @click="line('in2')">
					线路选项三
					<span>Wechat Stream</span>
				</a>
			</div>
			<div class="iframe-box" v-if="isplay">
				<div id="player" class="iframe"></div>
				<div class="parent-container" v-if="staticImageVisible">
					<div class="circle">
						<img src="../../../public/commencement/images/tanhao.png" class="img-center" />
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="box mobile" v-else>
		<div class="video-image-height">
			<img class="top-image" src="/commencement/images/bk-1.png" alt="none" />
			<img class="NYU" src="/commencement/images/NYUlogo.svg" />
			<img class="classof" src="/commencement/images/class.svg" />
			<img class="number" src="/commencement/images/2024.svg" />
			<img class="postgraduate" src="/commencement/images/graduate.svg" />
			<div class="iframe-box iframe-jump" v-if="showDefault">
				<img
					class="play-iconmobile"
					src="../../../public/commencement/images/default.gif"
					alt="none"
				/>
			</div>
			<div class="iframe-box" v-else>
				<div id="player" class="iframe" v-if="!staticImageVisible"></div>
				<div class="parent-container" v-if="staticImageVisible">
					<img src="../../../public/commencement/images/tanhao.png" class="img-center" />
				</div>
				<div class="btn-group-invedio" v-if="isplay">
					<a href="javascript:;" @click="line('in')" class="purple">
						线路选项一
						<span>Bilibili</span>
					</a>
					<a href="javascript:;" @click="line('out')" class="yellow">
						线路选项二
						<span>Overseas Lines</span>
					</a>
					<a href="javascript:;" class="purple" @click="line('in2')">
						线路选项三
						<span>Wechat Stream</span>
					</a>
				</div>
			</div>
			<div class="btn-group-outvedio">
				<a href="javascript:;" @click="line('in')" class="purple">
					线路选项一
					<span>Bilibili</span>
				</a>
				<a href="javascript:;" @click="line('out')" class="yellow">
					线路选项二
					<span>Overseas Lines</span>
				</a>
				<a href="javascript:;" class="purple" @click="line('in2')">
					线路选项三
					<span>Wechat Stream</span>
				</a>
			</div>
			<img class="end-image" src="../../../public/commencement/images/bk-1.png" alt="none" />
		</div>
	</div>
</template>
<script>
import loadAPI from "@/utils/youtube-widgetapi";
export default {
	name: "nyu-youtube",
	data() {
		return {
			isplay: false,
			isShow: false,
			iframeVisible: true,
			staticImageVisible: false,
			isWeChat: false,
			ismobile: false,
			viewRatio: 16 / 9,
			id: "",
			hideBtn: false,
			inOrOut: "in",
			isLoading: true,
			youtube: process.env.VUE_APP_ID,
			player: null,
			showDefault: true,
		};
	},
	created() {
		this.fetchConfig();
	},
	mounted() {
		this.onFirstResize();
		this.init();
		window.addEventListener("resize", this.onResize, false);
		if (this.viewRatio <= 3 / 5) {
			this.loadYoutubeIframeAPI();
		}
	},
	destroyed() {
		window.removeEventListener("resize", this.onResize);
		this.removeYoutubeIframeAPI();
	},
	methods: {
		removeYoutubeIframeAPI() {
			const existingScript = document.getElementById('www-widgetapi-script');
			if (existingScript) {
				existingScript.parentNode.removeChild(existingScript);
			}
			window.YT = undefined;
			window.YTConfig = undefined;
			window.onYTReady = undefined;
			window.yt = undefined;
			window.ytDomDomGetNextId = undefined;
			window.ytEventsEventsCounter = undefined;
			window.ytEventsEventsListeners = undefined;
			window.ytLoggingGelSequenceIdObj_ = undefined;
			window.ytLoggingTransportTokensToCttTargetIds_ = undefined;
			window.ytLoggingTransportTokensToJspbCttTargetIds_ = undefined;
			window.ytNetworklessLoggingInitializationOptions = undefined;
			window.ytPubsub2Pubsub2Instance = undefined;
			window.ytPubsub2Pubsub2IsAsync = undefined;
			window.ytPubsub2Pubsub2SkipSubKey = undefined;
			window.ytPubsub2Pubsub2SubscribedKeys = undefined;
			window.ytPubsub2Pubsub2TopicToKeys = undefined;
			window.ytPubsubPubsubInstance = undefined;
			window.ytPubsubPubsubIsSynchronous = undefined;
			window.ytPubsubPubsubSubscribedKeys = undefined;
			window.ytPubsubPubsubTopicToKeys = undefined;
			window.ytglobal = undefined;
			if (window.YT && window.YT.setConfig) {
				window.YT.setConfig({});
			}
		},
		loadYoutubeIframeAPI() {
			this.removeYoutubeIframeAPI();
			loadAPI();
			window.onYouTubeIframeAPIReady = this.initializeYoutubePlayer;
		},
		initializeYoutubePlayer() {
			const self = this;
			self.showDefault = false;
			// 确保 YT API 已加载
			if (window.YT) {
				setTimeout(() => {
					this.player = new window.YT.Player("player", {
						height: "360",
						width: "640",
						videoId: this.id,
						events: {
							'onReady': self.onPlayerReady,
							'onStateChange': self.onPlayerStateChange,
							'onError': self.onPlayError,
						},
					});
				}, 1000);
			}
		},
		onPlayError() {
			this.staticImageVisible = true;
		},
		onPlayerStateChange(event) {

		},
		onPlayerReady(event) {
			event.target.playVideo();
		},
		isFullScreenVideo() {
			return document.fullscreenElement !== null;
		},
		onFirstResize() {
			this.viewRatio = window.innerWidth / window.innerHeight;
		},
		createPlayerDom() {
			// 销毁现有的播放器实例
			if (this.player) {
				this.player.destroy();
				this.player = null;
			}
			const existingIframe = document.getElementById('player');
			if (existingIframe && existingIframe.tagName === 'IFRAME') {
				existingIframe.parentNode.removeChild(existingIframe);
			}
		},
		onResize() {
			if (!this.isFullScreenVideo()) {
				const last_viewRatio = this.viewRatio;
				this.viewRatio = window.innerWidth / window.innerHeight;
				this.createPlayerDom();
				if (last_viewRatio <= 3 / 5 && this.viewRatio > 3 / 5) {
					this.playHandler();
				}
				else if (last_viewRatio > 3 / 5 && this.viewRatio <= 3 / 5) {
					this.isplay = false;
					this.loadYoutubeIframeAPI();
				}
			}
		},
		fetchConfig() {
			const noCache = new Date().getTime();
			fetch(`../zhibo/config/config.json?${noCache}`)
				.then((response) => response.json())
				.then((data) => {
					this.id = data["YOUTUBE"];
					this.isLoading = false;
				})
				.catch((error) => console.error("Error fetching config:", error));
		},
		init() {
			const isMobile = navigator.userAgent.toLowerCase().includes("mobile");
			const isPortrait = window.matchMedia("(orientation: portrait)").matches;
			if (isMobile && isPortrait) {
				this.isShow = true;
				this.ismobile = true;
			} else {
				this.isShow = false;
				if (isMobile) {
					this.ismobile = true;
				}
			}
		},
		playHandler() {
			this.isplay = true;
			this.loadYoutubeIframeAPI();
		},

		line(type) {
			if (type == "in") {
				this.$router.push("/mainland");
			}
			if (type == "in2") {
				this.$router.push("/mainland2");
			}
		},
	},
};
</script>
<style>
.box {
	height: 100%;
	width: 100%;
}

.video-image-width {
	background-image: url("../../assets/2024/2024mastervk.jpg");
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.btn-group-outvedio,
.btn-group-invedio {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
}

.video-image-width .btn-group-outvedio {
	z-index: 10;
	bottom: 5%;
	position: absolute;
}

.btn-group-outvedio>a,
.btn-group-invedio>a {
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 20px;
	text-decoration: none;
	width: 200px;
	padding: 16px;
	box-sizing: border-box;
	line-height: 1.5;
	font-weight: 700;
	font-size: 22px;
}

.btn-group-outvedio>a.yellow,
.btn-group-invedio>a.yellow {
	color: rgb(255, 255, 255);
	background-color: rgb(255, 186, 1);
	border: 2px solid rgb(255, 255, 255);
}

.btn-group-outvedio>a.purple,
.btn-group-invedio>a.purple {
	color: rgb(255, 186, 1);
	background-color: rgb(96, 15, 168);
	border: 2px solid rgb(255, 186, 1);
}

.btn-group-outvedio>a>span,
.btn-group-invedio>a>span {
	font-size: 16px;
	font-family: "Noto Sans SC", Times, serif;
	font-weight: 400;
}

.btn-group-outvedio>a:focus,
.btn-group-invedio>a:focus {
	outline: none;
}

.video-image-height {
	background-color: #57068c;
	background-image: url(../../../public/commencement/images/2024ConvocationKV.png);
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	height: auto;
	width: 100%;
	display: flex;
	align-items: center;
	text-align: center;
	flex-direction: column;
}

.mobile .parent-container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.parent-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	background: #000;
}

.circle {
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.15);
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 35vw;
	height: 35vw;
}

.img-center {
	max-width: 50%;
}

.NYU {
	width: 20%;
	height: auto;
	margin-top: 0%;
}

.shanghai {
	width: 54%;
	height: auto;
}

.postgraduate {
	width: 68%;
	height: auto;
	margin-top: 6%;
	margin-bottom: 6%;
}

.classof {
	margin-top: 6%;
	width: 30%;
	height: auto;
}

.number {
	width: 40%;
	height: auto;
	margin-top: 5%;
}

.iframe {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	transform: translate(-50%, -50%);
}

.play-icon {
	width: 45%;
	height: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.active {
	color: white;
}

.end-image {
	width: 100%;
	height: auto;
	margin-top: auto;
	bottom: 0;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.pc .iframe-box {
	/* z-index: 999; */
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	border: 0;
	background-image: url("../../../public/commencement/images/default.gif");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100px 100px;
}

/* Phone */
@media (max-width: 667px) {
	.box {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	.video-image-height {
		min-height: 100%;
	}
}

/* pc */
@media (min-width: 660px) {
	.box {
		min-width: 660px;
		margin-left: auto;
		margin-right: auto;
		overflow: hidden;
	}
}

.pc .iframe-box {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	border: 0;
}

.mobile .contain-box {
    background-image: url("../../assets/2024/2024 Convocation KV.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #000;
    position: relative;
    border: 2px solid #fff;
    max-width: 94%;
	width: 100%;
	padding-top: 56.2%;
}

.mobile .contain-box .iframe-box {
	position: relative;
	width: 100%;
	background-image: url("../../../public/commencement/images/default.gif");
	/* 加载动画图标的路径 */
	background-repeat: no-repeat;
	background-position: center;
	background-size: 30px 30px;
	padding-top: 0;
}

.btn-group-outvedio>a>span,
.btn-group-invedio>a>span {
	font-size: 16px;
	font-family: "Noto Sans SC", Times, serif;
	font-weight: 400;
}

@media (min-height: 200px) and (max-height: 700px) {
	.box {
		margin-left: auto;
		margin-right: auto;
	}

	.btn-group-outvedio>a {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 10px;
		text-decoration: none;
		width: 100px;
		padding: 8px;
		box-sizing: border-box;
		line-height: 1;
		font-weight: 700;
		font-size: 14px;
	}

	.btn-group-invedio>a {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 10px;
		text-decoration: none;
		width: 23vw;
		padding: 8px;
		box-sizing: border-box;
		line-height: 1;
		font-weight: 700;
		font-size: 12px;
	}
}

@media (min-width: 200px) and (max-width: 700px) {
	.btn-group-outvedio>a {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 10px;
		text-decoration: none;
		width: 100px;
		padding: 8px;
		box-sizing: border-box;
		line-height: 1;
		font-weight: 700;
		font-size: 14px;
	}

	.btn-group-invedio>a {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 10px;
		text-decoration: none;
		width: 23vw;
		padding: 8px;
		box-sizing: border-box;
		line-height: 1;
		font-weight: 700;
		font-size: 12px;
	}
}

@media screen and (orientation: landscape) {
	.mobile .box {
		padding-top: 56%;
	}

	.video-image-height .iframe-box,
	.iframe-box {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
		padding-top: 0%;
	}

	/* Phone */
	@media (max-width: 668px) {
		.box {
			width: 100%;
			margin-left: auto;
			margin-right: auto;
		}

		.video-image-height {
			min-height: 100%;
		}
	}
}

.iframe-jump .play-iconmobile {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50px;
}
</style>
