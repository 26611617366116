<template>
	<router-view />
</template>

<script>
export default {
	name: "App",
};
</script>

<style>
html,
body {
	padding: 0;
	margin: 0;
	font-family: "Gotham", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei",
		"WenQuanYi Micro Hei", Arial, sans-serif;
}
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
	width: 100%;
}

.container {
	min-height: 100vh;
}
.img-responsive {
	display: block;
	width: 100%;
	height: auto;
}
.img-mb {
	display: none;
}

#app {
	height: 100vh;
}
.banner {
	position: relative;
}
.branding-menu {
	width: 100%;
	max-width: 1450px;
	position: absolute;
	left: 50%;
	top: 0;
	transform: translateX(-50%);
	padding: 15px 30px;
	box-sizing: border-box;
}
.logo {
	margin-left: 15px;
	display: inline-block;
}
.logo img {
	display: block;
	max-width: 224px;
	filter: brightness(0) invert(1);
}
.commencement-info {
	position: absolute;
	top: 35%;
	left: 0;
	width: 100%;
	height: 65%;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 10px 30px 40px;
	box-sizing: border-box;
}
.commencement-info .home-innerblock {
	display: none;
}
.commencement-info h1 {
	color: #57068c;
	font-weight: 600;
	font-size: 40px;
	text-transform: uppercase;
}
.commencement-info h2 {
	color: #f2d57d;
	font-size: 50px;
	margin: 0;
	font-weight: 600;
}

#demo {
	max-width: 1450px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 30px;
	padding-right: 30px;
	font-weight: 600;
	font-size: 52px;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
}
#demo .digits,
#demo .d,
#demo .h,
#demo .m,
#demo .s {
	display: inline-block;
	margin: 0;
}
#demo .d,
#demo .h,
#demo .m,
#demo .s {
	padding: 0;
	text-align: left;
	margin-right: 30px;
}
#demo .s {
	margin-right: 0px;
}
#demo .d {
	color: #907bff;
}
#demo .h {
	color: #907bff;
}
#demo .m {
	color: #907bff;
}
#demo .s {
	color: #907bff;
}
#demo .digits {
	padding: 0;
	text-align: right;
}
.home-innerblock {
	max-width: 1450px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 30px;
	padding-right: 30px;
}
.home-innerblock p {
	text-align: center;
	font-size: 18px;
	font-weight: 500;
	color: #57068c;
	margin: 0;
	padding: 50px;
}
.pr {
	position: relative;
}
.btn-group {
	display: flex;
	flex-direction: row;
	justify-content: center;
	position: absolute;
	top: 50%;
	left: 0;
	padding: 0 30px;
	width: 100%;
	transform: translate(0, -50%);
	box-sizing: border-box;
	font-family: "宋体", "Times New Roman", Times, serif;
}
.btn-group > a {
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 20px;
	color: #fff;
	text-decoration: none;
	width: 200px;
	padding: 16px;
	background-color: #57068c;
	font-size: 20px;
	box-sizing: border-box;
	line-height: 1.5;
	box-shadow: 0 0 1px 1px #57068c;
	position: relative;
	transition: all 0.25s ease-in;
}
.btn-group > a:after {
	content: "";
	position: absolute;
	top: -20px;
	left: -20px;
	width: 100%;
	height: 100%;
	border: 3px solid #57068c;
	z-index: -1;
	transition: all 0.25s ease-in;
}
.btn-group > a:last-child:after {
	top: -20px;
	left: auto;
	right: -20px;
}
.btn-group > a:hover {
	background-color: rgba(255, 187, 1, 0.8);
	box-shadow: 0 0 1px 1px rgba(255, 187, 1, 0.8);
}
.btn-group > a:hover:last-child:after {
	top: -10px;
	left: auto;
	right: -10px;
	border-color: #ffba01;
}
.btn-group > a:hover::after {
	top: -10px;
	left: -10px;
	border-color: #ffba01;
}
.btn-group > a > span {
	font-size: 14px;
	font-family: "Times New Roman", Times, serif;
}
.player-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.75);
}
@media screen and (max-width: 1200px) {
	.commencement-info {
		top: 70%;
		left: 50%;
		width: 90%;
		height: auto;
		transform: translateX(-50%);
		background-color: #fff;
	}
	.commencement-info h1 {
		font-weight: 600;
		font-size: 30px;
	}
	.commencement-info h2 {
		font-size: 26px;
		font-weight: 600;
	}
	.commencement-info .home-innerblock {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		display: block;
		margin-top: 0;
		box-sizing: border-box;
	}
	.commencement-info .home-innerblock p {
		padding: 20px 20px;
	}
	#demo {
		color: #57068c;
	}
	.home-innerblock {
		display: none;
		margin-top: 15%;
	}
}
@media screen and (max-width: 1100px) {
	.commencement-info {
		top: 60%;
		left: 50%;
		width: 90%;
		height: auto;
		transform: translateX(-50%);
		background-color: #fff;
	}
}
@media screen and (max-width: 860px) {
	.home-innerblock {
		margin-top: 18%;
	}
}
@media screen and (max-width: 670px) {
	#demo {
		font-size: 46px;
	}
	.btn-group > a {
		width: 190px;
		padding: 10px;
		font-size: 16px;
		text-align: center;
	}
	.btn-group > a > span {
		font-size: 14px;
	}
}
@media screen and (max-width: 450px) {
	.img-pc {
		display: none;
	}
	.img-mb {
		display: block;
	}
	.container {
		background-size: 45px;
	}
	.commencement-info {
		padding-bottom: 30px;
	}
	.commencement-info h1 {
		font-weight: 600;
		font-size: 22px;
	}
	.commencement-info h2 {
		font-size: 26px;
		font-weight: 600;
		margin-bottom: 10px;
	}
	#demo {
		color: #57068c;
		font-size: 22px;
	}
	#demo .d,
	#demo .h,
	#demo .m,
	#demo .s {
		margin-right: 16px;
	}
	.home-innerblock {
		margin-top: 25%;
		padding: 20px 10px;
	}
}
@media screen and (max-width: 375px) {
	.home-innerblock {
		margin-top: 35%;
		padding: 30px 10px;
	}
}
.bg {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}
.bg-zb {
	background: url("./assets/bg.png") no-repeat center center/contain;
}
.bg-bk {
	background: url("./assets/bg.png") no-repeat center center/contain;
}
.bg-yjs {
	background: url("./assets/yjs-bg.jpg") no-repeat center center/contain;
}
.icp {
	position: absolute;
	left: 0;
	bottom: 0;
	margin: 0;
	padding: 0;
	padding: 10px;
}
.icp a {
	color: #f0f0f0;
	font-size: 14px;
}
@media screen and (orientation: portrait) {
	.bg {
		background-size: contain;
	}
}
@media screen and (orientation: landscape) {
	.bg {
		background-size: cover;
	}
}

@media screen and (orientation: portrait) and (max-width: 414px) {
	.youtube-container .btn-group {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.youtube-container .btn-group > a:first-child {
		margin-right: 30%;
	}
	.youtube-container .btn-group > a:last-child {
		margin-left: 30%;
	}
}
</style>
