export default {
    VENUE: 'Venue',
    FAMILY_GUESTS: 'Family & Guests',
    FAQ: 'FAQ',
    MEET_THE_SENIORS: 'Meet the Seniors',
    CONTACT: 'Contact',
    PAST_COMMENCEMENTS: 'Past Commencements',

    LANGUAGE: '中文',
    CONGRATULATIONS_CLASS_OF_2023: 'Congratulations Class of 2023！',
    QUICK_LINKS: 'Quick Links',
    FACULTY: 'Faculty',
    STUDENTS: 'Students',
    STAFF: 'Staff',
    PARENTS: 'Parents',
    ALUMNI: 'Alumni',
    GET_IN_TOUCH: 'Get In Touch',
    CONTACT_US: 'Contact Us',
    CAMPUS_TOUR: 'Campus Tour',
    JOBS: 'Jobs',
    PORTAL_CAMPUSES: 'Portal Campuses',
    NYU: 'NYU',
    SHANGHAI: 'Shanghai',
    ABU_DHABI: 'Abu Dhabi',
    CONNECT_WITH_NYU_SHANGHAI: 'Connect with NYU Shanghai',
    LINK: "https://commencement.shanghai.nyu.edu/en/{link}",
    top: '1. Click the icon in the upper right corner',
    bottom: '2. Choose default browser to open'
}