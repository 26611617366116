import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from './en'
import zh from './zh'

Vue.use(VueI18n)

const locale = localStorage.getItem('commencement_language') || navigator.language.split('-')[0]

export default new VueI18n({
    locale: ['zh', 'en'].includes(locale.toLocaleLowerCase()) ? locale.toLocaleLowerCase() : 'en',
    messages: {
        en,
        zh,
    }
})