export default {
    VENUE: '毕业典礼场馆',
    FAMILY_GUESTS: '家人&嘉宾',
    FAQ: '常见问题',
    MEET_THE_SENIORS: '毕业生故事',
    CONTACT: '联系我们',
    PAST_COMMENCEMENTS: '往期精彩',

    LANGUAGE: 'English',
    CONGRATULATIONS_CLASS_OF_2023: '祝贺2023届同学顺利毕业！',
    QUICK_LINKS: '快速链接',
    FACULTY: '教师',
    STUDENTS: '学生',
    STAFF: '员工',
    PARENTS: '家长',
    ALUMNI: '校友',
    GET_IN_TOUCH: '联系我们',
    CONTACT_US: '联系方式',
    CAMPUS_TOUR: '校园参观',
    JOBS: '职位招聘',
    PORTAL_CAMPUSES: '校园门户',
    NYU: '美国',
    SHANGHAI: '上海',
    ABU_DHABI: '阿布扎比',
    CONNECT_WITH_NYU_SHANGHAI: '关注上海纽约大学',
    LINK: "https://commencement.shanghai.nyu.edu/cn/{link}",
    top: '1. 点击右上角',
    bottom: '2. 选择在默认浏览器中打开'
}